@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import '~@gsp-design-system/elements/src/assets/variables.css';
@import 'intl-tel-input/build/css/intlTelInput.css';

@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
